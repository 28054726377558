import "./index.scss";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import InputSearch from "../../ui/InputSearch";
import InspectionDetailsModal from "../../ui/InspectionDetailsModal";
import withFetch from "../../hocs/withFetch";
import Geocode from "react-geocode";
import axios from "axios";
import ConfirmModal from "../../ui/ConfirmModal";
import UpdateInspectionModal from "../../ui/UpdateInspectionModal";
import UpdateAddressModal from "../../ui/UpdateAddressModal";
import { v2 } from "../../services/axios";
import moment from "moment";

export default
@withRouter
@withFetch((props) => ({
  getInspectors: () => ({
    allInspectors: {
      url: `/domoreport/uploader/allInspectors`,
      force: true,
    },
  }),
  getBrokers: () => ({
    allBrokers: {
      url: `/domoreport/uploader/allBrokers`,
      force: true,
    },
  }),
  publishExploreContent: () => ({
    updatedExploreContent: {
      url: `/domoreport/uploader/emailExploreContentFor/${props.reportID}`,
      method: "PUT",
      force: true,
      refresh: true,
      then: (res) => {
        if (res.success) {
          props.getOneReport();
        }
      },
    },
  }),
  publishReport: () => ({
    publishedReport: {
      url: `/domoreport/uploader/publishDomoreport/${props.reportID}`,
      method: "PUT",
      force: true,
      refreshing: true,
      then: (res) => {
        if (res.success) {
          props.getOneReport();
        }
      },
    },
  }),
  placeOnHold: () => ({
    placedOnHold: {
      url: `/domoreport/${props.reportID}/on-hold`,
      method: "PATCH",
      force: true,
      refreshing: true,
      then: (res) => {
        props.getOneReport();
      },
    },
  }),
}))
class InspectionDetails extends Component {
  state = {
    date: new Date(),
    inspectionPerson: null,
    inspection_company: null,
    agent: null,
    allInspectors: [],
    allAgents: [],
    weather: null,
    majordomo_people_ref_id: null,
    showConfirmModal: false,
    showAddressModal: false,
    showInspectionModal: false,
    showAutomationConfirm: false,
    showModal: false,
    modalLabel: "",
    message: "",
    lat: null,
    lng: null,
    unixTime: null,
  };

  componentDidMount() {
    const { report } = this.props;
    let date = new Date();

    if (Object.keys(report).length > 0) {
      let yesterday = date.getDate() - 1;
      date.setDate(yesterday);
      date.setHours(12, 0, 0);

      this.setState({
        date: report.inspection_datetime
          ? new Date(report.inspection_datetime)
          : date,
        weather: report.inspection_weather_comment,
        majordomo_people_ref_id: report.majordomo_people_ref_id,
      });
    }

    // Use Open Weather Map Geocode service
    // axios.get(
    //   `http://api.openweathermap.org/geo/1.0/direct?q=${city name},{state code},{country code}&limit={limit}&appid={API key}`
    // );

    //   Geocode.setApiKey("AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU");
    //   Geocode.fromAddress(
    //     report.PropertyAddressFull +
    //       ", " +
    //       report.PropertyAddressCity +
    //       ", " +
    //       report.PropertyAddressState
    //   ).then((response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     let dt = Math.round(date.getTime() / 1000);
    //     let apiKey = "6ebc55bc4d470af5edb360de5d691055";

    //     axios
    //       .get(
    //         `https://api.openweathermap.org/data/2.5/onecall/timemachine?lat=${lat}&lon=${lng}&dt=${dt}&appid=${apiKey}&units=imperial`
    //       )
    //       .then((res) => {
    //         let weather = res.data.current;
    //         if (!this.state.weather) {
    //           this.setState({
    //             weather:
    //               Math.round(weather.temp) +
    //               "°F - " +
    //               weather.weather[0].description,
    //             lat: lat,
    //             lng: lng,
    //             unixTime: dt,
    //           });
    //         } else {
    //           this.setState({
    //             lat: lat,
    //             lng: lng,
    //             unixTime: dt,
    //           });
    //         }
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //   });
  }

  componentDidUpdate(prevProps) {
    const { allBrokers, allInspectors, report } = this.props;
    const { inspection_company, agent } = this.state;

    if (
      prevProps.report &&
      prevProps.report.majordomo_people_ref_id !==
        report.majordomo_people_ref_id
    ) {
      this.setState({
        majordomo_people_ref_id: report.majordomo_people_ref_id,
      });
    }

    if (report && report.inspector_ref_id && inspection_company === null) {
      this.setState({
        inspection_company: {
          id: report.inspector_ref_id,
          name: report.inspector_person_name,
          company: report.inspection_company,
        },
      });
    }

    if (report && report.broker_ref_id && agent === null) {
      this.setState({
        agent: {
          id: report.broker_ref_id,
          name: report.broker_person_name,
          company: report.brokerage,
        },
      });
    }

    if (!allBrokers && !allInspectors) {
      this.props.getBrokers();
      this.props.getInspectors();
    }
  }

  toggleModal = (e, label) => {
    e.preventDefault();
    this.setState({
      showModal: !this.state.showModal,
      modalLabel: label,
    });
  };

  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  toggleAutomationConfirm = () => {
    this.setState({ showAutomationConfirm: !this.state.showAutomationConfirm });
  };

  handleChange = (key, value) => {
    if (key === "date") {
      const { lat, lng } = this.state;
      let apiKey = "6ebc55bc4d470af5edb360de5d691055";
      let dt = Math.round(value.getTime() / 1000);

      axios
        .get(
          `https://api.openweathermap.org/data/2.5/onecall/timemachine?lat=${lat}&lon=${lng}&dt=${dt}&appid=${apiKey}&units=imperial`
        )
        .then((res) => {
          let weather = res.data.current;
          this.setState({
            weather:
              Math.round(weather.temp) +
              "°F - " +
              weather.weather[0].description,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    this.setState({ [key]: value });
  };

  // Handle Inspector/Agent search
  handleSearch = (key, value) => {
    const { allInspectors, allBrokers } = this.props;
    let results = [];

    if (value == null) {
      value = "";
    }

    if (allInspectors.value && key === "inspection_company") {
      allInspectors.value.inspectors.map((inspector) => {
        if (
          (inspector.inspection_company &&
            inspector.inspection_company
              .toUpperCase()
              .includes(value.toUpperCase())) ||
          (inspector.inspector_person_name &&
            inspector.inspector_person_name
              .toUpperCase()
              .includes(value.toUpperCase()))
        ) {
          let result = {
            id: inspector.inspector_ref_id,
            name: inspector.inspector_person_name.trim(),
            company: inspector.inspection_company.trim(),
            email: inspector.inspection_email,
            phone: inspector.inspection_phone,
          };
          results.push(result);
        }
      });

      this.setState({
        [key]: value,
        allInspectors: results,
      });
    } else if (allBrokers.value && key === "agent") {
      allBrokers.value.brokers.map((broker) => {
        if (
          (broker.brokerage &&
            broker.brokerage.toUpperCase().includes(value.toUpperCase())) ||
          (broker.broker_person_name &&
            broker.broker_person_name
              .toUpperCase()
              .includes(value.toUpperCase()))
        ) {
          let result = {
            id: broker.broker_ref_id,
            name: broker.broker_person_name || "",
            company: broker.brokerage || "",
            email: broker.brokerage_email,
            phone: broker.brokerage_phone,
          };
          results.push(result);
        }
      });

      this.setState({
        [key]: value,
        allAgents: results,
      });
    }
  };

  updateReport = (e) => {
    e.preventDefault();
    const { report } = this.props;
    const { date, inspection_company, agent, weather } = this.state;

    let data = {
      inspection_datetime: date.toISOString().slice(0, 19).replace("T", " "),
      inspector_ref_id: inspection_company ? inspection_company.id : null,
      broker_ref_id: agent ? agent.id : null,
      inspection_weather_comment: weather,
    };

    if (report.majordomo_notes === null || report.majordomo_notes === "") {
      // Thanks for having Majordomo on your team for the second half of your home inspection. We appreciate the opportunity. If you have any questions or need any updates, simply email us or use the instant chat button on this page. We are here for you 24/7/365. \nNotes: \n\n
      data.majordomo_notes =
        "-Any reference to mold and/or bacterial growths should be reviewed by a specialist.\n\n-All structural and foundation issues should be reviewed by a structural specialist. \n\n-When needed, anti-tip devices for kitchen stoves and anti-siphon devices for spigots can be purchased at your local home store for $5-$10 and are easily installed.";
    }

    this.props.updateReport(data);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { majordomo_people_ref_id } = this.state;
    const { report, role } = this.props;
    if (role === "PUBLISHER") {
      if (report.domoreport_published) {
        this.props.updateReport({ domoreport_published: 0 });
      } else if (!report.domoreport_published) {
        let queued = JSON.parse(localStorage.getItem("queued-projects"));
        if (queued) {
          delete queued[report.unique_id];
          localStorage.setItem("queued-projects", JSON.stringify(queued));
        }
        this.props.publishReport();
      }
    } else if (role === "ANALYST") {
      if (majordomo_people_ref_id) {
        this.toggleConfirmModal();
      } else {
        if (this.props.assignAnalyst) {
          this.props.assignAnalyst();
        }
      }
    }
  };

  handleEditPerson = (e, type) => {
    e.preventDefault();
    const { report } = this.props;
    const { agent, inspection_company } = this.state;

    let person = null;
    if (type === "agent") {
      person = {
        id: agent.id || report.broker_ref_id,
        name: agent.name || report.broker_person_name,
        company: agent.company || report.brokerage,
        email: agent.email || report.brokerage_email,
        phone: agent.phone || report.brokerage_phone,
      };
    } else if (type === "inspection_company") {
      person = {
        id: inspection_company.id || report.inspector_ref_id,
        name: inspection_company.name || report.inspector_person_name,
        company: inspection_company.company || report.inspection_company,
        email: inspection_company.email || report.inspection_email,
        phone: inspection_company.phone || report.inspection_phone,
      };
    }

    this.setState({
      modalLabel: type,
      inspectionPerson: person,
      showModal: true,
    });
  };

  toggleInspectionModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { showInspectionModal } = this.state;

    this.setState({ showInspectionModal: !showInspectionModal });
  };

  toggleAddressModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { showAddressModal } = this.state;

    this.setState({ showAddressModal: !showAddressModal });
  };

  handleAutomationRequest = () => {
    const { report } = this.props;
    if (report.submitted_for_identification_at) {
      this.toggleAutomationConfirm();
    } else {
      this.startAutomationService(report.unique_id);
    }
  };

  startAutomationService = async (id) => {
    try {
      const { status } = await v2.post(`/domoreports/${id}/automation`);
      if (status === 201) {
        this.setState({ showAutomationConfirm: false });
        this.props.getOneReport();
      }
    } catch (error) {
      console.error(error);
    }
  };

  cancelReport = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await v2.post(`/domoreports/${id}/cancel`);
      if (status === 201) {
        this.props.history.push("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { report, role, mdPeople, userCanEdit, updatingReport } = this.props;
    const {
      showConfirmModal,
      showAddressModal,
      showInspectionModal,
      showAutomationConfirm,
      showModal,
      modalLabel,
      agent,
      inspection_company,
      inspectionPerson,
      allInspectors,
      allAgents,
      weather,
      majordomo_people_ref_id,
      date,
      message,
    } = this.state;

    let action = "Publish Report";
    let status = "primary";
    if (role === "PUBLISHER") {
      if (report.domoreport_published) {
        action = "Unpublish Report";
        status = "danger";
      }
    } else if (role === "ANALYST") {
      status = report.status;
      if (!majordomo_people_ref_id) {
        action = "Claim Domoreport";
      } else if (majordomo_people_ref_id) {
        action = "Submit For Review";
      } else if (report.status === "IN_REVIEW") {
        action = "In Review";
      } else if (report.domoreport_published) {
        action = "Published";
      }
    }

    return (
      <div>
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="email"
            id="email"
            tabIndex="-1"
            value=""
            onChange={() => {
              return null;
            }}
          />
        </div>

        {showModal && (
          <InspectionDetailsModal
            getBrokers={this.props.getBrokers}
            getInspectors={this.props.getInspectors}
            handleChange={this.handleChange}
            inspectionPerson={inspectionPerson}
            label={modalLabel}
            toggleModal={this.toggleModal}
          />
        )}

        <ConfirmModal
          action="Submit"
          close={this.toggleConfirmModal}
          handleSubmit={this.props.submitForReview}
          subtext="You will not be able to make changes after submitting this report for review"
          title="Submit For Review?"
          variant="warning"
          show={showConfirmModal}
        />

        <ConfirmModal
          action="Re-run Automation"
          close={this.toggleAutomationConfirm}
          handleSubmit={() => this.startAutomationService(report.unique_id)}
          subtext={`Automation service ran on ${moment(
            report.submitted_for_identification_at
          ).format(
            "MMMM Do YYYY"
          )}. Are you sure you want to run it again. Projects will be duplicated`}
          title="Re-run Automation Service?"
          variant="danger"
          show={showAutomationConfirm}
        />

        {showInspectionModal && (
          <UpdateInspectionModal
            reportId={report.domoreport_log_id}
            close={this.toggleInspectionModal}
          />
        )}

        {showAddressModal && (
          <UpdateAddressModal
            reportId={report.domoreport_log_id}
            close={this.toggleAddressModal}
          />
        )}

        {message && (
          <div className="md-diy-sidebar-message">
            <p>{message}</p>
            <p onClick={() => this.handleChange("message", null)}>x</p>
          </div>
        )}

        <div className={`md-diy-sidebar-content ${showModal ? "blur" : ""}`}>
          <div className="md-diy-sidebar-info">
            <h3 className="md-diy-sidebar-header mb-0">Inspection Details</h3>

            <div className="admin-links-wrapper">
              <div>
                <Link
                  className="md-legacy-link"
                  to={`/report/${report.domoreport_log_id}`}
                >
                  <i className="fa fa-arrow-circle-left mr-1" />
                  Legacy View
                </Link>
              </div>

              {role === "PUBLISHER" && (
                <div>
                  <a href="/" onClick={this.toggleInspectionModal}>
                    <i className="fa fa-file-pdf-o mr-1" /> Update Inspection
                    PDF
                  </a>
                </div>
              )}

              {role === "PUBLISHER" && (
                <div>
                  <a href="/" onClick={this.toggleAddressModal}>
                    <i className="fa fa-home mr-1" />
                    Update Property Address
                  </a>
                </div>
              )}

              {role === "PUBLISHER" && report.status !== "CANCELLED" && (
                <div>
                  <a
                    href="/"
                    onClick={(e) => this.cancelReport(e, report.unique_id)}
                  >
                    <i className="fa fa-times-circle mr-1" />
                    Cancel Report
                  </a>
                </div>
              )}
            </div>

            <p className="m-0">
              <b>Identification started</b>:{" "}
              {report.submitted_for_identification_at ? (
                <span>
                  {moment(report.submitted_for_identification_at).format(
                    "MMMM Do YYYY"
                  )}{" "}
                  at{" "}
                  {moment(report.submitted_for_identification_at).format(
                    "h:mm:ss a"
                  )}
                </span>
              ) : (
                "N/A"
              )}
            </p>

            <p className="m-0">
              <b>Classification started</b>:{" "}
              {report.submitted_for_classification_at ? (
                <span>
                  {moment(report.submitted_for_classification_at).format(
                    "MMMM Do YYYY"
                  )}{" "}
                  at{" "}
                  {moment(report.submitted_for_classification_at).format(
                    "h:mm:ss a"
                  )}
                </span>
              ) : (
                "N/A"
              )}
            </p>

            <p>
              <b>Status</b>: <span>{report.status}</span>
            </p>

            <hr />
          </div>

          <div className="md-diy-sidebar-form">
            {report.domoscore_notes && <label>Customer Notes</label>}
            <p>{report.domoscore_notes}</p>

            {report.domoscore_notes && <hr />}

            <label>Date of Inspection</label>
            <div className="md-diy-input-wrapper">
              <img src="/assets/icons/Date.svg" className="md-diy-input-icon" />
              <DatePicker
                disabled={!userCanEdit}
                selected={date || new Date(report.inspection_datetime)}
                onSelect={(value) => this.handleChange("date", value)}
              />
            </div>

            <label>
              Inspection Company{" "}
              {!!inspection_company && (
                <a
                  href="/"
                  onClick={(e) =>
                    this.handleEditPerson(e, "inspection_company")
                  }
                >
                  <i className="fa fa-pencil" /> Edit
                </a>
              )}
            </label>
            <InputSearch
              disabled={!userCanEdit}
              label="inspection_company"
              placeholder="Search for inspection company"
              icon="/assets/icons/Inspection Company.svg"
              results={allInspectors}
              value={inspection_company}
              toggleModal={this.toggleModal}
              handleChange={this.handleChange}
              handleSearch={this.handleSearch}
            />

            <label>
              Broker or Agent{" "}
              {!!agent && (
                <a href="/" onClick={(e) => this.handleEditPerson(e, "agent")}>
                  <i className="fa fa-pencil" /> Edit
                </a>
              )}
            </label>
            <InputSearch
              disabled={!userCanEdit}
              label="agent"
              placeholder="Search for broker or agent"
              icon="/assets/icons/Broker.svg"
              results={allAgents}
              value={agent}
              toggleModal={this.toggleModal}
              handleChange={this.handleChange}
              handleSearch={this.handleSearch}
            />

            <label>Weather Conditions</label>
            <div className="md-diy-input-wrapper">
              <img
                src="/assets/icons/Weather.svg"
                className="md-diy-input-icon"
                alt=""
              />
              <input
                type="text"
                placeholder="Sunny"
                value={
                  weather !== null
                    ? weather
                    : report.inspection_weather_comment || ""
                }
                onChange={(e) => this.handleChange("weather", e.target.value)}
                disabled={!userCanEdit}
              />
            </div>

            {role === "PUBLISHER" && <label>Analyst</label>}
            {role === "PUBLISHER" && (
              <div>
                {majordomo_people_ref_id &&
                  mdPeople.map((person) => {
                    if (
                      person.majordomo_people_ref_id === majordomo_people_ref_id
                    ) {
                      return (
                        <div
                          className="md-person-card"
                          key={majordomo_people_ref_id}
                        >
                          {person.mdp_profile_pic || person.user_profile_pic ? (
                            <img
                              src={
                                person.mdp_profile_pic ||
                                person.user_profile_pic
                              }
                            />
                          ) : (
                            <div className="md-person-img-placeholder" />
                          )}
                          <div>
                            <p className="md-person-name">
                              <b>
                                {person.user_first_name.toUpperCase()}{" "}
                                {person.user_last_name.toUpperCase()}
                              </b>
                            </p>
                            <p>{person.mdp_title}</p>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                {!majordomo_people_ref_id && (
                  <div className="md-person-card">
                    <div className="md-person-img-placeholder" />
                    <p>
                      <b>UNCLAIMED</b>
                    </p>
                  </div>
                )}
              </div>
            )}

            <button
              href="#"
              className={`btn btn-primary ${updatingReport ? "disabled" : ""} ${
                !userCanEdit ? "d-none" : ""
              }`}
              disabled={updatingReport || !userCanEdit}
              onClick={this.updateReport}
            >
              Save
            </button>
            <div className="row">
              <div className="col-12 col-lg-6">
                <button
                  href="#"
                  className={`btn btn-primary ${
                    updatingReport || report.explore_content_published
                      ? "disabled"
                      : ""
                  } ${!userCanEdit ? "d-none" : ""}`}
                  onClick={this.props.publishExploreContent}
                  disabled={!userCanEdit || report.explore_content_published}
                >
                  {!report.explore_content_published
                    ? "Publish Explore"
                    : "Explore Published"}
                </button>
              </div>

              {role === "ANALYST" ? (
                <div className="col-12 col-lg-6">
                  <button
                    className={`btn btn-${status} ${
                      updatingReport ? "disabled" : ""
                    }`}
                    onClick={this.handleSubmit}
                  >
                    {action}
                  </button>
                </div>
              ) : (
                <div className="col-12 col-lg-6">
                  <button
                    className={`btn btn-${status} ${
                      updatingReport ? "disabled" : ""
                    } ${!userCanEdit ? "d-none" : ""}`}
                    disabled={!userCanEdit}
                    onClick={this.handleSubmit}
                  >
                    {action}
                  </button>
                </div>
              )}

              {role === "PUBLISHER" && (
                <div className="col-12 col-lg-6">
                  <button
                    href="#"
                    className={`btn btn-primary ${
                      updatingReport || report.status === "ON_HOLD"
                        ? "disabled"
                        : ""
                    } ${!userCanEdit ? "d-none" : ""}`}
                    onClick={this.props.placeOnHold}
                    disabled={!userCanEdit || report.status === "ON_HOLD"}
                  >
                    {report.status === "ON_HOLD"
                      ? "Report ON HOLD"
                      : "Place on hold"}
                  </button>
                </div>
              )}

              {role === "PUBLISHER" && (
                <div className="col-12 col-lg-6">
                  <button
                    href="#"
                    className={`btn btn-primary ${
                      updatingReport || report.status === "ON_HOLD"
                        ? "disabled"
                        : ""
                    } ${!userCanEdit ? "d-none" : ""}`}
                    onClick={() => this.handleAutomationRequest()}
                    disabled={
                      !userCanEdit ||
                      report.status === "IDENTIFYING" ||
                      report.status === "CLASSIFYING"
                    }
                  >
                    Start Automation
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
