import "./index.scss";
import React from "react";

export function ProjectListCard({
  project,
  updateStep,
  updatePageNumber,
  getPriority,
  setProjectId,
  handleCheck,
  defaultChecked,
}) {
  function handleClick(e) {
    if (e.target.type !== "checkbox") {
      setProjectId(project.domoreport_project_log_id);
      updatePageNumber(project.inspection_page_num_start);
      updateStep(2);
    }
  }

  return (
    <div
      key={project.domoreport_project_log_id}
      className={`md-diy-project border-${getPriority(
        project.project_priority_ref_id
      )}`}
      onClick={handleClick}
    >
      <div>
        <input
          type="checkbox"
          id={project.domoreport_project_log_id}
          onChange={(e) => handleCheck(e, project.status)}
          checked={defaultChecked}
        />
        <p>
          <b>{project.project_name_override || project.cache_project_name}</b> -{" "}
          {project.inspection_problem_comment}
        </p>
      </div>
      <span>
        ${project.cache_project_cost_low} - ${project.cache_project_cost_high}
      </span>
    </div>
  );
}
